(function($) {
	var controller = new ScrollMagic.Controller();

	$('.animate-section').each(function() {
		var scrollAnimation = new TimelineMax({
			ease: Expo.easeOut
		});

		var stagger = 30;
		var sceneOffset = 100;
		var fadeSpeed = 200;
		var topSpeed = 75;

		var $window = $(window);

		if ($(window).width <= 768) {
			sceneOffset = 0;
			stagger = $window.height * 0.8;
			fadeSpeed = 120;
			topSpeed = 75;
		}

		scrollAnimation.add(TweenMax.staggerTo($('.scrollFadeInUp', $(this)), topSpeed, {top:0}, stagger), 0);
		scrollAnimation.add(TweenMax.staggerTo($('.scrollFadeInUp', $(this)), fadeSpeed, {opacity: 1}, stagger), 0);

		var scrollSectionScene = new ScrollMagic.Scene ({
			triggerElement: '#' + $(this).attr('id'),
			triggerHook: 1,
			reverse: true,
			offset: sceneOffset,
			duration: $(this).outerHeight()
		})
		.setTween(scrollAnimation);
		// .addIndicators({name: 'Section'})

		controller.addScene(scrollSectionScene);
	});
})(jQuery); // Fully reference jQuery after this point.
